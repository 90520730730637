import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Grid.module.scss'

const GridRow = ({ children, className, align, indent }) => {
  return (
    <div className={classNames(styles.GridRow, className)} data-align={align}>
      <div className={styles.GridRow__inner} data-indent={indent}>
        {children}
      </div>
    </div>
  )
}

GridRow.defaultProps = {
  indent: 0,
  align: 'default',
}

GridRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  indent: PropTypes.number,
  align: PropTypes.string,
}

export { GridRow }
