import React, { useEffect } from 'react'
import LazyLoad from 'vanilla-lazyload'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Img.module.scss'
import './Img.css' // non-dynamic classes (loaded, lazy)

const IS_BROWSER = typeof window !== 'undefined'

const Img = ({
  src,
  srcset,
  sizes,
  alt,
  lazyLoad,
  outerClass,
  outerStyle,
  imgClass,
  imgStyle,
  objectFit,
  objectPosition,
  fillContainer,
  aspectRatio,
  showPlaceholder,
  placeholderColor,
}) => {
  const lazyLoadClass = lazyLoad ? 'lazy' : ''

  // only initialize lazy loading one time for the app
  if (IS_BROWSER && !document.lazyLoadInstance && lazyLoad) {
    document.lazyLoadInstance = new LazyLoad({
      elements_selector: '.lazy',
      class_loaded: 'loaded',
    })
  }

  // update lazyLoad after first rendering of every image
  useEffect(() => {
    if (typeof document !== 'undefined' && lazyLoad) {
      document.lazyLoadInstance.update()
    }
  }, [lazyLoad])

  useEffect(() => {
    if (typeof document !== 'undefined' && lazyLoad) {
      document.lazyLoadInstance.update()
    }
  })

  return (
    <div
      className={classNames(styles.Img, outerClass)}
      style={{
        paddingTop:
          aspectRatio && !fillContainer ? `${100 / aspectRatio}%` : null,
        ...outerStyle,
      }}
      data-fill-container={fillContainer}
    >
      <img
        alt={alt}
        className={classNames(styles.element, lazyLoadClass, imgClass)}
        data-src={src}
        data-srcset={srcset}
        data-sizes={sizes}
        data-fill-container={!!aspectRatio}
        style={{ objectFit, objectPosition, ...imgStyle }}
      />
      {showPlaceholder && (
        <div
          className={styles.placeholder}
          style={{ backgroundColor: placeholderColor }}
        />
      )}
    </div>
  )
}

Img.defaultProps = {
  alt: '',
  lazyLoad: true,
  outerStyle: {},
  imgStyle: {},
  objectFit: 'cover',
  objectPosition: 'center',
  fillContainer: false,
}

Img.propTypes = {
  src: PropTypes.string,
  srcset: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string,
  lazyLoad: PropTypes.bool,
  outerClass: PropTypes.string,
  outerStyle: PropTypes.object,
  imgClass: PropTypes.string,
  imgStyle: PropTypes.object,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  fillContainer: PropTypes.bool,
  aspectRatio: PropTypes.number,
  showPlaceholder: PropTypes.bool,
  placeholderColor: PropTypes.string,
}

export { Img }
