import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import * as styles from './Block.module.scss'

const Block = ({
  className,
  children,
  topPadding,
  bottomPadding,
  prev,
  next,
  background,
  scrollEnabled,
  transitionDelay,
}) => {
  const [isTransitioned, setIsTransitioned] = useState(false)

  const handleWaypointEnter = () => setIsTransitioned(true)

  useEffect(() => {
    let timer
    if (!scrollEnabled) {
      timer = setTimeout(() => setIsTransitioned(true), transitionDelay)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [scrollEnabled, transitionDelay])

  const section = (
    <section
      className={classNames(styles.Block, className)}
      data-top-padding={topPadding}
      data-bottom-padding={bottomPadding}
      data-next={next}
      data-prev={prev}
      data-background={background}
      data-transition-in={isTransitioned}
    >
      {children}
    </section>
  )

  if (scrollEnabled) {
    return (
      <Waypoint
        onEnter={handleWaypointEnter}
        scrollableAncestor={typeof window !== 'undefined' ? window : undefined}
      >
        {section}
      </Waypoint>
    )
  }

  return <>{section}</>
}

Block.defaultProps = {
  topPadding: true,
  bottomPadding: true,
  prev: '',
  next: '',
  background: 'default',
  scrollEnabled: true,
  transitionDelay: 750,
}

Block.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  topPadding: PropTypes.bool,
  bottomPadding: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
  background: PropTypes.string,
  scrollEnabled: PropTypes.bool,
  transitionDelay: PropTypes.number,
}

export { Block }
