import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Grid.module.scss'

const Grid = ({ children, className }) => {
  return <div className={classNames(styles.Grid, className)}>{children}</div>
}

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export { Grid }
