export const getImage = (image) => {
  const imageSharp = image?.fixed || image?.fluid
  const width = image.dimensions?.width || 100
  const height = image.dimensions?.height || 100

  return {
    src: imageSharp?.src || image.url,
    srcset: imageSharp?.srcSet,
    sizes: imageSharp?.sizes,
    aspectRatio: width / height,
    width,
    height,
    alt: image.alt,
  }
}
