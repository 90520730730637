import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Grid.module.scss'

const GridColumn = ({ children, className, width }) => {
  return (
    <div
      className={classNames(styles.GridColumn, className)}
      data-column
      data-column-width={width ? width.toString().toLowerCase() : 'auto'}
    >
      {children}
    </div>
  )
}

GridColumn.defaultProps = {
  width: 'default',
}

GridColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.string,
}

export { GridColumn }
